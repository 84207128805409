<template>
  <div id="form-crud-category">
    <form @submit.prevent="initSubmit">
      <h3 class="mt-3 mb-3">Informations globales</h3>
      <div class="form-group">
        <label for="name">Nom</label>
        <input class="form-control" id="name" v-model="category.name" type="text">
        <div v-if="errorsForm.name">
          <p class="form-error text-danger" v-for="(error, index) in errorsForm.name.errors" :key="index">{{ error }}</p>
        </div>
      </div>
      <div class="form-group">
        <label for="code">Code</label>
        <input class="form-control" id="code" v-model="category.code" type="text">
        <div v-if="errorsForm.code">
          <p class="form-error text-danger" v-for="(error, index) in errorsForm.code.errors" :key="index">{{ error }}</p>
        </div>
      </div>
      <div class="form-group">
        <label for="vat-rate">TVA</label>
        <input class="form-control" id="vat-rate" v-model="category.vatRate" type="number" step="0.01">
        <div v-if="errorsForm.vatRate">
          <p class="form-error text-danger" v-for="(error, index) in errorsForm.vatRate.errors" :key="index">{{ error }}</p>
        </div>
      </div>
      <h3 class="mt-3 mb-3">Exclure la catégorie de l'export bilan mensuel ?</h3>

      <div class="form-check">
        <input
            class="form-check-input"
            type="radio"
            id="is-excluded-yes"
            v-model="category.isExcluded"
            :checked="category.isExcluded === true"
            v-bind:value="true"
        >
        <label class="form-check-label" for="is-excluded-yes">
          Oui
        </label>
      </div>
      <div class="form-check">
        <input
            class="form-check-input"
            type="radio"
            id="is-excluded-no"
            v-model="category.isExcluded"
            :checked="category.isExcluded === false"
            v-bind:value="false"
        >
        <label class="form-check-label" for="is-excluded-no">
          non
        </label>
      </div>

      <h3 class="mt-3 mb-3">Affecter un parent à une catégorie</h3>

      <div class="form-group">
        <label for="select-cat">Catégorie Parente</label>
        <select id="select-cat" class="form-control" v-model="selectParent" @input="changeParent()">
          <option :value="null">pas de parent</option>
          <option v-for="(category, index) in CategoryModule.categories" :key="index" :value="category.id">
            {{ category.name }}
          </option>
        </select>
        <div v-if="errorsForm.parent">
          <p class="form-error text-danger" v-for="(error, index) in errorsForm.parent.errors" :key="index">{{ error }}</p>
        </div>
      </div>
      <input type="submit" class="btn btn-success" :value="valueButtonSubmit" />
    </form>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    category: Object,
    id: Number,
    actionForm: String,
    selectedParent: Number
  },
  data() {
    return {
      errorsForm: [],
      selectParent: this.selectedParent
    }
  },
  watch: {
    selectedParent: function() {
      this.selectParent = this.selectedParent
    }
  },
  created() {
    this.$store.dispatch('fetchCategories', {
      perPage: 0,
      page: 0
    })
  },
  methods: {
    changeParent: function(){
      this.$emit('input',this.selectParent)
    },
    initSubmit()
    {
      // Handle parent
      this.category.parent = this.selectParent

      // If Edit or create
      if(this.actionForm === 'edit')
      {
        this.editCategory()
      }
      else {
        this.createCategory()
      }
    },
    /**
     * Create category (submit form)
     */
    createCategory() {

      this.$store.dispatch('createCategory', this.category)
          .then(() => {
            this.refresh('created')
          })
          .catch((errors) => {
            this.errorsForm = errors
          })
    },
    /**
     * Update category (submit form)
     */
    editCategory() {
      this.$store.dispatch('updateCategory', { id: this.id, category: this.category })
          .then(() => {
            this.refresh('edited')
          })
          .catch((errors) => {
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to category-list after submit
     * Refresh ErrorsForm
     */
    refresh(emitAction)
    {
      // Emit new shop event
      if(emitAction === 'created')
      {
        this.$emit(emitAction)
      }
      else
      {
        this.$emit(emitAction, this.category.parent)
      }

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = 'Enregistrer les modifications'
      }
      else {
        value = 'Créer une catégorie'
      }

      return value
    },
    ...mapState(['CategoryModule'])
  }
}
</script>

<style scoped>

</style>
